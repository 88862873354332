.Black {
  font-family: "Nunito-Black";
}

.BlackItalic {
  font-family: "Nunito-BlackItalic";
}

.Bold {
  font-family: "Nunito-Bold";
}

.BoldItalic {
  font-family: "Nunito-BoldItalic";
}

.ExtraBold {
  font-family: "Nunito-ExtraBold";
}

.ExtraBoldItalic {
  font-family: "Nunito-ExtraBoldItalic";
}

.ExtraLight {
  font-family: "Nunito-ExtraLight";
}

.ExtraLightItalic {
  font-family: "Nunito-ExtraLightItalic";
}

.Italic {
  font-family: "Nunito-Italic";
}

.Light {
  font-family: "Nunito-Light";
}

.LightItalic {
  font-family: "Nunito-LightItalic";
}

.Medium {
  font-family: "Nunito-Medium";
}

.MediumItalic {
  font-family: "Nunito-MediumItalic";
}

.Regular {
  font-family: "Nunito-Regular";
}

.SemiBold {
  font-family: "Nunito-SemiBold";
}

.SemiBoldItalic {
  font-family: "Nunito-SemiBoldItalic";
}

:root {
  --lz-primary: #0d4976;
  --lz-secondary: #0be8ff;
  --lz-tertiary: #ffeab7;
  --lz-quaternary: #ffffff;
  --lz-quinary: #000000bd;
}

.modal-backdrop {
  z-index: -1;
}

.global-title {
  @extend .Black;
  font-size: 6.180556vw;
  line-height: 6.180556vw;
}

.global-subtitle {
  @extend .Black;
  font-size: 3.819444vw;
  line-height: 3.832639vw;
}

.global-body {
  @extend .SemiBold;
  font-size: 1.458333vw;
  line-height: 2.111111vw;
}

#app {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.loading-page {
  margin: 0;
  height: 100%;
  overflow: hidden;
}

#loader {
  width: 100%;
  height: 100vh;
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 99;

  .background {
    position: absolute;
    width: 100%;
    bottom: 0;
    right: 0;
  }

  .status {
    display: flex;
    flex-direction: column;
    z-index: 9;
    align-items: center;

    .text {
      @extend .Black;
      margin-top: 26.1px;
      color: var(--lz-quaternary);
      font-size: 2vw;
      text-align: center;
    }

    img {
      width: 74.47px;
      height: 74.47px;
      -webkit-transform: rotate(360deg);
      animation: rotation 2s linear infinite;
      -moz-animation: rotation 2s linear infinite;
      -webkit-animation: rotation 2s linear infinite;
      -o-animation: rotation 2s linear infinite;
    }

    @-webkit-keyframes rotation {
      from {
        -webkit-transform: rotate(0deg);
      }

      to {
        -webkit-transform: rotate(360deg);
      }
    }
  }
}

#header {
  display: flex;
  padding: 56.9px 17% 59.6px 16.958333%;
  justify-content: space-evenly;
  flex-wrap: nowrap;
  align-items: center;
  width: 100%;
  background-color: var(--lz-quaternary);

  .circle {
    width: 36px;
    height: 36px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;

    .icon {
      width: 22px;
      height: 22px;
      background-color: var(--lz-primary);
    }
  }

  .nav-sections {
    display: flex;

    .nav-link {
      padding: 0 2vw;
    }
  }

  .nav-sections.no-mobile {
    @extend .no-mobile;
  }

  .nav-item {
    position: relative;
    height: 2vw;
    font-size: 1.2vw;
    font-family: "Nunito-ExtraBold";
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    .nav-link {
      color: var(--lz-quaternary);

      .icon {
        background-color: var(--lz-secondary);
        width: 0.9vw;
        height: 0.9vw;
        margin-left: 5px;
      }
    }

    .nav-item:hover .nav-link {
      color: var(--lz-primary);
    }
  }

  .submenu {
    display: none;
  }

  .catalogue {
    .nav-link {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      flex-wrap: nowrap;
    }
  }

  .catalogue:hover {
    .submenu {
      display: flex;
      flex-direction: column;
      position: absolute;
      top: 2vw;
      z-index: 99;
      background-color: var(--lz-quaternary);
      width: 100%;
      color: var(--lz-primary);
      text-align: left;
      border-radius: 2vw;

      .item {
        height: 2vw;
        padding: 0 2vw;
        text-transform: capitalize;
      }

      .item:hover {
        color: var(--lz-secondary);
      }
    }
  }
}

/* BANNER 2 */
#category,
#us,
#catalogue,
#contact {
  #header {
    padding: 56.9px 17% 59.6px 16.958333%;

    .circle {
      background-color: var(--lz-secondary);

      .icon {
        background-color: var(--lz-quaternary);
      }
    }

    .nav-link {
      color: var(--lz-primary);
    }

    .nav-link:hover {
      color: var(--lz-secondary);
    }
  }
}

#home {
  #header {
    position: absolute;
    top: 0;
    left: 0;
    background-color: transparent;
    z-index: 10;

    .circle {
      background-color: var(--lz-quaternary);
    }

    .nav-item {
      .nav-link {
        .icon {
          background-color: var(--lz-quaternary);
          width: 0.9vw;
          height: 0.9vw;
        }
      }

      .nav-link:hover {
        color: var(--lz-primary);
      }
    }
  }

  #banner {
    background-color: var(--lz-secondary);
    display: flex;
    flex-direction: column;

    .wave-carousel {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      margin-bottom: -5px;
    }

    .carousel-indicators {
      bottom: 3.278946%;

      li {
        background-color: var(--lz-secondary);
        border-radius: 50%;
        width: 10px;
        height: 10px;
      }
    }
  }

  #features {
    background-color: var(--lz-quaternary);
    margin-top: -5px;

    .numbers {
      padding: 90.9px 19.194444% 0 19.548611%;
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      align-items: center;
      justify-content: space-between;

      .item {
        text-align: center;
        @extend .Black;
      }

      .count {
        color: var(--lz-secondary);
        font-size: 2.8vw;
        line-height: 2.8vw;
      }

      .text {
        color: var(--lz-primary);
        font-size: 2vw;
        line-height: 2vw;

        p {
          margin: 0;
        }
      }

      .divisor {
        width: 1px;
        background-color: #a5c0d3;
        height: 110.97px;
      }
    }

    .content {
      position: relative;

      .background {
        position: absolute;
        z-index: 2;
      }

      .mottled {
        width: 133.18px;
        height: 222.46px;
        bottom: 20.836221%;
        left: 0;
      }

      .wave-volume {
        width: 69.804167vw;
        bottom: -5vw;
        right: 0;
      }

      .commitment {
        display: flex;
        padding: 0 8.486111% 0 11.597222%;
        align-items: center;
        justify-content: space-between;
        flex-direction: row;
        flex-wrap: nowrap;
        margin-top: 178.1px;

        .text {
          z-index: 2;
          max-width: 45%;

          .title p {
            @extend .global-subtitle;
            color: var(--lz-primary);
            margin: 0;
          }

          .body {
            margin-top: 8px;
          }

          .body p {
            @extend .global-body;
            color: #667c7c;
            margin: 0;
          }
        }

        img {
          z-index: 2;
          max-width: 50%;
        }
      }

      .ingredients {
        display: flex;
        justify-content: space-around;
        flex-direction: row;
        flex-wrap: nowrap;
        padding-left: 17.027778%;
        padding-right: 16.819444%;
        margin-top: 97.8px;
        padding-bottom: 97.8px;

        .item {
          z-index: 2;

          .circle {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 14.930556vw;
            height: 14.930556vw;
            border-radius: 50%;
            background-color: var(--lz-secondary);

            .icon {
              background-color: var(--lz-quaternary);
            }

            .milk {
              width: 4.513889vw;
              height: 7.69375vw;
            }

            .not-aditive {
              width: 9.74375vw;
              height: 9.74375vw;
            }

            .fruit {
              width: 6.944444vw;
              height: 7.405555vw;
            }
          }

          .text {
            margin-top: 14.21px;
          }

          .text p {
            @extend .ExtraBold;
            color: var(--lz-primary);
            font-size: 2vw;
            line-height: 2vw;
            margin: 0;
            text-align: center;
          }
        }
      }
    }
  }

  #flavor {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
    flex-wrap: nowrap;
    padding: 102.67px 6.493056% 13vw 11.743056%;
    background-color: var(--lz-quaternary);
    position: relative;
    overflow: hidden;

    .background {
      position: absolute;
      z-index: 2;
      bottom: -1vw;
      left: 0;
      width: 100%;
      height: 100%;
    }

    .bottom {
      position: absolute;
      z-index: 2;
      width: 39.386111%;
      max-width: 567px;
      bottom: -27%;
      right: 4.923611%;
    }

    .content-image {
      z-index: 2;
      max-width: 50%;
    }

    .text {
      max-width: 45%;
      z-index: 2;

      .title p {
        @extend .global-subtitle;
        color: var(--lz-secondary);
        margin: 0;
      }

      .body {
        margin-top: 8px;
      }

      .body p {
        @extend .global-body;
        color: var(--lz-quaternary);
        margin: 0;
      }
    }
  }

 
  
  .modal-pedidos-container {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: #111111bd;
    z-index: 10;
    display: flex;
    opacity: 0;
    pointer-events: none;
    transition: opacity .6s .9s;
    --transform: translateY(-100vh);
    --transition: transform .8s;

    .modal-pedidos {
      border-radius: 1.398611vw;
      position: relative;
      margin: auto;
      background-color: var(--lz-quaternary);
      width: 50.069444vw;
      display: flex;
      transform: var(--transform);
      transition: var(--transition);

      .modal-image-container {
        width: 18.694444vw;
        img {
          height: 100%;
          width: 100%;
          border-top-left-radius: 1.398611vw;
          border-bottom-left-radius: 1.398611vw;
        }
      }

      .modal-content-container {
        width: fit-content;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 4.958333vw 3.25vw 3.208333vw 3.263889vw;

        .modal-close-circle {
          top: 1.25vw;
          left: 46.083333vw;
          position: absolute;
          background-color: var(--lz-secondary);
          width: 2.666667vw;
          height: 2.666667vw;
          border-radius: 50%;
          display: flex;
          justify-content: center;
          align-items: center;
          .close.icon {
            background-color: var(--lz-primary);
            width: 1.402778vw;
            height: 1.402778vw;
          }
        }

        .pedidos-ya-icon.icon {
          background-color: #ED2F42;
          height: 4.333333vw;
          width: 19.194444vw;
          margin-bottom: 2.222222vw;
        }

        .modal-text-container {
          margin-bottom: 3.1875vw;
          .modal-text {
            margin: 0;
            color: var(--lz-primary);
            font-size: 1.975vw;
            line-height: 2.369444vw;
            text-align: center;
            white-space: nowrap;
          }
        }

        .modal-link {
          border-radius: 1.628472vw;
          text-align: center;
          display: inline-block;
          font-size: 1.975vw;
          color: var(--lz-primary);
          padding: 0.215278vw 1.034722vw 0.229167vw 1.305556vw;
          background-color: var(--lz-secondary);
          text-decoration: none;
        }
      }
    }
  }

   // pedidos modal modifier class
   .modal-show {
    opacity: 1;
    pointer-events: unset;
    transition: opacity .6s;
    --transform: translateY(0);
    --transition: transform .8s .8s;
  }
}

#us {
  .wrap-content {
    overflow: hidden;
  }

  .sections {
    position: relative;

    .content {
      padding: 0 23.472222%;
    }
  }

  .content {
    .header {
      @extend .global-title;
      text-align: center;
      margin-bottom: 0.784722vw;
    }

    .body {
      @extend .global-body;
      text-align: justify;
    }

    p {
      @extend .global-body;
      margin: 0;
    }
  }

  .floating {
    position: absolute;
  }

  #header {
    padding: 56.9px 17% 59.6px 16.958333%;

    .circle {
      background-color: var(--lz-secondary);

      .icon {
        background-color: var(--lz-quaternary);
      }
    }

    .nav-link {
      color: var(--lz-primary);
    }

    .nav-link:hover {
      color: var(--lz-secondary);
    }
  }

  #banner {
    position: relative;

    .background {
      width: 100%;
      filter: brightness(0.8);
    }

    .text {
      font-size: 10vw;
      line-height: 13vw;
      color: var(--lz-quaternary);
      text-align: center;
      position: absolute;
      top: 13.75vw;
      width: 100%;
      text-shadow: 9px 8px 10px #171717;
    }
  }

  #mission-vision {
    margin-top: -100px;
    padding-bottom: 10vw;
    z-index: 11;

    .wave-top {
      position: relative !important;
      margin-bottom: -1vw;
      width: 100%;
    }

    .wave-bottom {
      width: 100%;
      background-color: var(--lz-quaternary);
      height: 12.361111vw;
      margin-top: -2.083333vw;
      z-index: -1;
    }

    .mottled {
      top: 0;
      left: 0;
      width: 19.722222vw;
    }

    .content {
      color: var(--lz-quaternary);
      background-color: var(--lz-secondary);
      padding-top: 1vw;

      .vision .body {
        @extend .global-body;
      }

      .mission {
        .body {
          @extend .global-body;
        }

        margin-bottom: 30.7px;
      }
    }
  }

  #history {
    background-color: var(--lz-quaternary);
    margin-top: -3vw;
    margin-bottom: -3vw;
    z-index: 10;

    .mottled {
      top: 189.9px;
      right: 0;
      z-index: 9;
      width: 16.0416vw;
    }

    .wave-bottom {
      width: 100%;
      position: relative;
      z-index: -1;
    }

    .content {
      color: var(--lz-primary);

      .header {
        padding-top: 3.763889vw;
      }

      .body p {
        @extend .global-body;
        margin-top: 22.8px;
      }
    }
  }

  #factory {
    .wave-top {
      top: 0;
      left: 0;
      width: 100%;
    }

    .wave-bottom {
      bottom: -1px;
      left: 0;
      width: 100%;
      z-index: 2;
      margin-bottom: 0;
    }

    .background {
      width: 100%;
    }

    .wave-vol {
      bottom: 0;
      left: 0;
      width: 31%;
    }
  }

  #whoweare {
    background-color: #e5f3fd;
    margin-top: -3vw;
    margin-bottom: -3vw;
    z-index: 10;

    .wave-bottom {
      bottom: 0;
      left: 0;
      width: 100%;
      z-index: 2;
    }

    .content {
      .header {
        @extend .global-subtitle;
        text-align: left;
        margin-bottom: 12.7px;
      }

      .header,
      .body {
        color: var(--lz-primary);
      }

      img {
        display: block;
        margin: auto;
        width: 100%;
        margin-top: 12.7px;
        margin-bottom: 57.9px;
      }
    }
  }

  #assemble {
    .background {
      width: 100%;
    }

    .wave-top {
      top: 0;
      left: 0;
      width: 100%;
    }

    .wave-bottom {
      bottom: 0;
      left: 0;
      width: 100%;
      z-index: 2;
      margin-bottom: -0.5vw;
    }

    .mottled {
      right: 0;
      top: 485px;
      z-index: 11;
    }
  }

  #last {
    background-color: #e5f3fd;
    margin-top: -3vw;
    z-index: 10;

    .content {
      .body {
        color: var(--lz-primary);
        padding-bottom: 40px;

        p {
          margin-bottom: 12px;
        }
      }
    }
  }
}

#category,
#catalogue {
  #banner {
    /* background-color: var(--lz-primary); */
    display: flex;
    flex-direction: column;

    .carousel-inner {
      .carousel-item {
        margin-bottom: -0.5vw;
      }

      .darkolivegreen {
        background: rgb(235, 209, 186);
        background: radial-gradient(circle, rgba(235, 209, 186, 1) 10%, rgba(111, 71, 62, 1) 100%);
      }

      .redcream {
        background: rgb(235, 209, 186);
        background: radial-gradient(circle, rgba(235, 209, 186, 1) 0%, rgba(229, 24, 46, 1) 100%);
      }

      .arequipe {
        background: rgb(235, 209, 186);
        background: radial-gradient(circle, rgba(235, 209, 186, 1) 0%, rgba(224, 151, 85, 1) 100%);
      }
    }

    .carousel-indicators {
      bottom: 14.278946%;

      [data-bs-target] {
        background-color: var(--lz-primary);
      }

      li {
        background-color: var(--lz-primary);
        border-radius: 50%;
        width: 10px;
        height: 10px;
      }
    }
  }

  .title {
    @extend .global-subtitle;
    color: var(--lz-primary);
  }

  #products {
    display: flex;
    flex-direction: column;
    padding-top: 72.9px;
    background-color: var(--lz-quaternary);
    padding: 1vw 10.354167% 0 10.354167%;
    margin-top: -1vw;

    .title {
      text-align: center;
      margin-bottom: 31.7px;
    }

    .subcategories {
      .name {
        @extend .Black;
        font-size: 2vw;
        line-height: 2vw;
        color: var(--lz-quaternary);
        background-color: var(--lz-primary);
        border-radius: 2vw;
        padding: 0.5vw 1vw;
        width: fit-content;
        margin-bottom: 43.2px;
        text-transform: capitalize;
      }

      .open-gallery {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    .gallery {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      align-items: flex-start;
      justify-content: center;
      max-width: 100%;
      overflow: hidden;

      .item:hover .title {
        color: var(--lz-secondary);
      }

      .item {
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        width: 33.333%;
        margin-bottom: 53.8px;
        cursor: pointer;
        padding: 0 1%;

        img {
          border-radius: 2vw;
        }

        .title {
          @extend .ExtraBold;
          text-align: center;
          font-size: 2.5vw;
          line-height: 2.5vw;
          margin-top: 2.513889vw;
          margin-bottom: 0;
        }

        .title:first-letter {
          text-transform: uppercase;
        }
      }
    }

    .open-gallery {
      margin-bottom: 3vw;

      .view {
        @extend .Black;
        background-color: var(--lz-quaternary);
        display: flex;
        align-items: center;
        align-content: center;
        height: 4vw;
        color: var(--lz-primary);
        border: 2px solid var(--lz-secondary);
        font-size: 2vw;
        line-height: 2vw;
        border-radius: 2vw;
        padding: 5.9px 30px;
        cursor: pointer;
      }

      .view:hover {
        color: var(--lz-quaternary);
        background-color: var(--lz-primary);
      }
    }
  }

  #categories {
    .wave-top {
      background-color: var(--lz-quaternary);
      width: 100%;
    }

    .content {
      display: flex;
      flex-direction: column;
      padding: 0 10.354167%;
      overflow: hidden;
      background-color: #e5f3fd;
      padding-bottom: 106.3px;

      .title {
        padding-left: 66px;
        text-align: left;
        margin-bottom: 49.5px;
        text-transform: capitalize;
      }

      .gallery::-webkit-scrollbar {
        width: 10px;
        height: 10px;
      }

      .gallery::-webkit-scrollbar-thumb {
        background: var(--lz-secondary);
        border-radius: 10px;
      }

      .gallery::-webkit-scrollbar-track {
        background: transparent;
      }

      .gallery {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-items: stretch;
        justify-content: space-between;
        overflow-x: auto;
        scroll-snap-type: x mandatory;
        scroll-behavior: smooth;
        -webkit-overflow-scrolling: touch;
        width: 100%;

        .item:hover .title {
          color: var(--lz-secondary);
        }

        .item {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          flex-wrap: nowrap;
          max-width: 32%;
          width: 32%;
          padding: 0 0.5%;
          margin-bottom: 10px;
          cursor: pointer;

          img {
            width: 100%;
            border-radius: 2vw;
          }

          .title {
            @extend .Black;
            display: flex;
            align-items: center;
            align-content: center;
            height: 4vw;
            color: var(--lz-quaternary);
            background-color: var(--lz-primary);
            font-size: 1.5vw;
            line-height: 1.5vw;
            border: 0;
            border-radius: 2vw;
            padding: 4.9px 22px;
            margin-top: 14.9px;
            margin-bottom: 0;
            white-space: nowrap;
          }
        }
      }
    }
  }
}

#subcategories {
  display: flex;
  flex-direction: column;
  padding: 0 10.354167%;
  background-color: var(--lz-quaternary);

  .content {
    display: flex;
    flex-direction: column;

    .title {
      font-size: 2vw;
      line-height: 2vw;
      color: var(--lz-quaternary);
      background-color: var(--lz-primary);
      border-radius: 2vw;
      padding: 0.5vw 1vw;
      width: fit-content;
      margin-bottom: 43.2px;
    }

    .hover-text {
      display: none;
    }
  }

  .content:hover {
    position: relative;
    cursor: pointer;

    .hover-text {
      @extend .Black;
      display: block;
      color: var(--lz-primary);
      justify-content: center;
      position: absolute;
      top: 35%;
      left: 0;
      width: 100%;
      text-align: center;
      font-size: 4vw;
    }

    .title {
      filter: opacity(35%);
    }

    .preview {
      filter: opacity(35%);
    }
  }

  .preview {
    display: flex;
    justify-content: space-between;
    margin-bottom: 87.4px;

    .item {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 32%;

      .title {
        margin-bottom: 0;
        margin-top: 36.2px;
        background-color: unset;
        color: var(--lz-primary);
        padding: unset;
        border-radius: unset;
      }

      img {
        width: 100%;
        border-radius: 2vw;
      }
    }
  }
}

#search {
  .modal-dialog {
    margin: 0;

    .modal-content {
      width: 100vw;
      border: 0;

      .modal-body {
        padding: 0;

        .bar {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: space-between;
          padding: 2.85vw 3.5vw;

          .text {
            @extend .Black;
            font-size: 3vw;
            line-height: 3vw;
            color: var(--lz-primary);
          }

          .search-container {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 72%;

            #searchbar {
              border: 0;
              outline: 0;
              border-bottom: 1px solid var(--lz-primary);
              margin-right: 2vw;
              height: 3vw;
              font-size: 3vw;
              line-height: 3vw;
              width: 100%;
              color: var(--lz-primary);
              @extend .Black;
            }

            #searchbar::placeholder {
              color: var(--lz-primary);
              @extend .Black;
            }

            .icon.search {
              background-color: var(--lz-primary);
              width: 3vw;
              height: 3vw;
            }
          }
        }

        #results {
          padding: 0 29vw 2vw 29vw;

          .item-result {
            @extend .global-body;
            margin-bottom: 6px;
            cursor: pointer;
          }

          .item-result:hover {
            color: var(--lz-primary);
          }
        }
      }
    }
  }
}

#product-details {
  .modal-body {
    padding: 0;
  }

  .modal-body-content {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    padding-bottom: 92.8px;
    padding-top: 16.1px;
    overflow: hidden;
    position: relative;
    padding-left: 17vw;
    padding-right: 15.006944vw;
    justify-content: space-between;
  }

  .left,
  .right {
    position: relative;
    width: 100%;
    justify-content: flex-start;
    display: flex;
    z-index: 9;
  }

  .left {
    .banner-product {
      border-radius: 2vw;
      width: 31.8826vw;
    }
  }

  .parent {
    @extend .ExtraBold;
    background-color: var(--lz-primary);
    width: fit-content;
    color: var(--lz-quaternary);
    font-size: 1.117361vw;
    border-radius: 1.5vw;
    padding: 0 0.659722vw;
    line-height: 1.407639vw;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 0.194444vw;
    text-transform: capitalize;
    margin-top: 3.611111vw;
    height: 1.528472vw;
  }

  .pink {
    background-color: #e6a1e2;
  }

  .blue {
    background-color: var(--lz-secondary);
  }

  .line {
    @extend .ExtraBold;
    color: var(--lz-primary);
    width: fit-content;
    border-radius: 1.5vw;
    font-size: 1.117361vw;
    padding: 0 0.659722vw;
    line-height: 1.407639vw;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 1.528472vw;
  }

  .formula {
    @extend .ExtraBold;
    color: var(--lz-primary);
    width: fit-content;
    border-radius: 1.5vw;
    font-size: 1.463194vw;
    line-height: 1.75625vw;
    padding: 0 0.659722vw;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 2.054861vw;
  }

  .base {
    @extend .ExtraBold;
    background-color: #c8c8c7;
    width: fit-content;
    border-radius: 1.5vw;
    padding: 0 0.659722vw 0 0;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 2.054861vw;
    margin-bottom: 1.5vw;

    .text-base {
      font-size: 1.463194vw;
      line-height: 1.75625vw;
      color: var(--lz-primary);
      margin-left: 0.5vw;
    }

    .icon {
      background-color: var(--lz-secondary);
      width: 3vw;
      height: 3vw;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;

      .milk,
      .fruit {
        background: var(--lz-quaternary);
        width: 2vw;
        height: 2vw;
      }
    }
  }

  .base.hidden {
    display: none;
  }

  .information {
    margin-bottom: 2vw;
  }

  .include-box {
    @extend .ExtraBold;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: center;
    font-size: 1.463194vw;
    line-height: 1.75625vw;

    .left-box {
      display: flex;
      flex-direction: column;
      color: var(--lz-primary);

      .kind {
        background-color: var(--lz-secondary);
        color: var(--lz-primary);
        padding: 0.1vw 1vw;
        border-radius: 1vw;
        margin-bottom: 0.5vw;
        width: fit-content;
        text-align: center;
      }

      .qty {
        padding-left: 2px;

        span {
          color: var(--lz-secondary);
        }
      }
    }

    .line-box {
      width: 2px;
      background-color: var(--lz-primary);
      height: 6vw;
      margin: 0 1vw;
      border-radius: 4px;
    }

    .right-box {
      background-color: var(--lz-primary);
      border-radius: 0.8vw;
      padding: 0.2vw 1vw;

      p {
        margin: 0;
      }

      .white {
        color: var(--lz-quaternary);
      }

      .blue {
        background-color: unset;
        color: var(--lz-secondary);
      }
    }
  }

  .title {
    @extend .global-subtitle;
    margin-bottom: 2vw;
    text-align: left;
    max-width: 25vw;
    color: var(--lz-primary);
  }

  .title:first-letter {
    text-transform: uppercase;
  }

  .subtitle {
    @extend .Black;
    color: var(--lz-primary);
    font-size: 1.5vw;
    line-height: 1.9vw;
    margin-bottom: 11.5px;
  }

  .description {
    @extend .global-body;
    color: var(--lz-primary);
    margin-bottom: 11.5px;

    b {
      @extend .Black;
    }
  }

  .ingredients {
    @extend .ExtraBold;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;

    .item {
      font-size: 1.3vw;
      background-color: var(--lz-secondary);
      color: var(--lz-quaternary);
      border-radius: 2vw;
      padding: 3px 1vw;
      margin-right: 4px;
      margin-bottom: 4px;
    }
  }

  .modal-close {
    position: absolute;
    top: 0;
    right: 28.7px;
    display: flex;
    z-index: 10;
    align-items: center;
    cursor: pointer;

    .text {
      @extend .ExtraBold;
      color: var(--lz-primary);
      font-size: 0.902778vw;
      line-height: 1.052778vw;
    }

    .circle {
      width: 48px;
      height: 48px;
      background-color: var(--lz-secondary);
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-left: 5.9px;

      .close {
        background-color: var(--lz-primary);
      }
    }
  }

  .wave-foot {
    position: absolute;
    bottom: -1vw;
    right: 0;
    z-index: 1;
    width: 67.929861%;
  }
}

#contact {
  position: relative;

  .information {
    background-color: var(--lz-secondary);
    color: var(--lz-quaternary);
    padding: 108.2px 16.958333% 48.5px 16.958333%;
    display: flex;
    flex-direction: column;
    position: relative;

    .title {
      @extend .Black;
      font-size: 5.180556vw;
      margin-bottom: 86px;
      text-align: center;
      z-index: 9;
    }

    .steps-container {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-between;

      .step {
        display: flex;
        flex-direction: column;
        width: 43.558314%;
        margin-bottom: 52.6px;

        .title {
          @extend .Black;
          font-size: 2.361111vw;
          border-radius: 2vw;
          padding: 2px 4px;
          margin-bottom: 15.2px;
          width: fit-content;
          background-color: var(--lz-primary);
        }

        .body {
          @extend .SemiBold;
          font-size: 1.458333vw;
          line-height: 1.8vw;
        }
      }
    }
  }

  .register {
    background-color: var(--lz-primary);
    color: var(--lz-quaternary);
    padding: 100px 31.944444% 151.2px 31.944444%;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    z-index: 9;
    overflow: hidden;

    .head {
      display: flex;
      margin-bottom: 59.6px;
      flex-direction: column;

      .title {
        @extend .global-subtitle;
        text-align: center;
        white-space: nowrap;
      }

      .text {
        @extend .SemiBold;
        text-align: justify;
        font-size: 1.458333vw;
        line-height: 1.8vw;
      }
    }

    .register-information {
      display: flex;
      flex-direction: column;
      width: 100%;
      align-items: center;

      .contact-form {
        display: flex;
        flex-direction: column;
        margin-bottom: 46px;
        width: 100%;

        input {
          border: 0;
          background-color: var(--lz-primary);
          margin-bottom: 43.2px;
          text-align: center;
          color: var(--lz-quaternary);
          // font-size: 1.458333vw;
          font-size: 1.2vw;
          border-bottom: 1px solid var(--lz-quaternary);
        }

        input::placeholder {
          color: var(--lz-quaternary);
          // font-size: 1.458333vw;
          font-size: 1.2vw;
        }

        input:focus {
          color: var(--lz-quaternary);
          // font-size: 1.458333vw;
          font-size: 1.2vw;
          border: none;
          outline: none;
          border-bottom: 1px solid var(--lz-quaternary);
        }
      }

      .send {
        @extend .Black;
        padding: 2px 20px;
        font-size: 2.361111vw;
        width: fit-content;
        cursor: pointer;
        background-color: var(--lz-secondary);
        color: var(--lz-primary);
        border-radius: 2vw;
      }
    }
  }

  .contact-float {
    position: absolute;
  }

  .left-berry {
    width: 11.354861vw;
    left: 0;
    top: 3.472222vw;
  }

  .right-tina {
    width: 14.451389vw;
    right: 0;
    bottom: 0;
    z-index: 2;
  }

  .wave-cream {
    width: 47.189583vw;
    right: 0;
    bottom: -21.524306vw;
    z-index: 1;
  }

  .left-icecream {
    width: 22.977083vw;
    left: 0;
    bottom: -20.065972vw;
    z-index: 10;
  }

  .left-mota {
    width: 16.029167vw;
    left: 0;
    top: 4.875vw;
  }

  .left-palet {
    width: 29.032639vw;
    left: 0;
    bottom: 227px;
    z-index: 10;
  }

  .right-mota {
    width: 34.059028vw;
    bottom: -8.090972vw;
    right: 7.715278vw;
    z-index: -1;
  }

  .right-berry {
    width: 9.369444vw;
    top: 3.263889vw;
    right: 0;
  }

  .right-icecream {
    width: 23.595139vw;
    bottom: -1px;
    right: 0;
  }

  .modal-form {
    display: flex;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 10;
    background-color: var(--lz-quinary);
    opacity: 0;
    pointer-events: none;
    transition: opacity .6s .9s;
    --transform: translateY(-100vh);
    --transition: transform .8s;

    .modal-container {
      background-color: var(--lz-quaternary);
      border-radius: 4.497917vw;
      display: flex;
      flex-direction: column;
      align-items: center;
      margin: auto;
      width: 40.825694vw;
      transform: var(--transform);
      transition: var(--transition);
    

      .modal-check {
        background-color: var(--lz-secondary);
        width: 6.116667vw;
        height: 6.116667vw;
        margin-bottom: 1.631944vw;
        margin-top: 2.354167vw;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
    
        .icon.white-check {
          background-color: var(--lz-quaternary);
          height: 2.484722vw;
          width: 3.297917vw;
          margin: 2.3125vw 1.409722vw 1.541667vw 1.409722vw;
        }
      }

      .modal-text-container {
        margin-bottom: 2.097222vw;
        width: fit-content;
        display: flex;
        flex-direction: column;
        .modal-text {
          margin-bottom: 0;
          display: flex;
          justify-content: center;
          color: var(--lz-primary);
          line-height: 5.176389vw;
          font-size: 5.284028vw;
        }
      }
      .modal-close {
        background-color: var(--lz-secondary);
        color: var(--lz-primary);
        font-size: 2.811111vw;
        cursor: pointer;
        border-radius: 1.628472vw;
        width: fit-content;
        margin-bottom: 3.784722vw;
        padding: 0.034722vw 2.055556vw;
      }

    }
  }

  // form modal modifier class
  .modal-show {
    opacity: 1;
    pointer-events: unset;
    transition: opacity .6s;
    --transform: translateY(0);
    --transition: transform .8s .8s;
  }

}

@mixin desktop-width {
  @media screen and (min-width: 591px) {
    @content;
  }
}

@mixin tablet-width {
  @media screen and (min-width: 591px) and (max-width: 768px) {
    @content;
  }
}

@mixin mobile-width {
  @media screen and (max-width: 590px) {
    @content;
  }
}

@include desktop-width {
  .only-mobile {
    display: none !important;
  }

  .no-desktop {
    display: none;
  }
}

@include tablet-width {
  #contact {
    .left-palet {
      bottom: 421px;
    }
  }
}

@include mobile-width {
  .global-title {
    @extend .Black;
    font-size: 15.059222vw;
    line-height: 11.368866vw;
  }

  .global-subtitle {
    @extend .Black;
    font-size: 10.033841vw;
    line-height: 9.338409vw;
  }

  .global-body {
    @extend .SemiBold;
    font-size: 4.737733vw;
    line-height: 8.527919vw;
  }

  .only-desktop {
    display: none !important;
  }

  .no-mobile {
    display: none;
  }

  #search {
    .modal-dialog {
      .modal-content {
        // padding: 16.76819vw 8.527919vw;

        .modal-body {
          .bar {
            flex-direction: column;
            .text {
              font-size: 7vw;
              margin-bottom: 4.5vw;
              margin-top: 7.9vw;
            }
            
            .search-container {
              margin-bottom: 7.9vw;
              #searchbar {
                height: 4.6vw;
                font-size: 4.6vw;
                line-height: 4.6vw;
              }

              .icon.search {
                width: 6vw;
                height: 6vw;
              }
            }
          }
        }
      }
    }
  }

  #header {
    position: relative;
    display: flex;
    height: 140.14px;
    background-color: var(--lz-quaternary) !important;
    padding: 32.7px 8.358714vw 33.5px 8.358714vw;
    justify-content: center;

    .menu-icon {
      position: absolute;
      right: 8.358714vw;
      top: 56.85px;
      height: fit-content;

      .nav-item {
        height: fit-content;

        .menu-mobile {
          width: 53.22px;
          height: 26.43px;
          background-color: #4f6572;
        }
      }
    }


    .modal-fullscreen {
      .modal-content {
        height: fit-content;
      }
    }
  }

  #menu-mobile {
    .modal-fullscreen {
      .modal-content {
        height: unset;
        border-bottom: 1.884941vw solid var(--lz-secondary);   
      }
    }

    .top {
      display: flex;
      position: relative;
      justify-content: center;
      height: 23.712352vw;
      align-items: center;

      .logo {
        width: 27.91709vw;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .modal-close {
        position: absolute;
        top: 8.781726vw;
        right: 9.729272vw;

        .icon.close {
          background-color: gray;
          width: 6.367174vw;
          height: 6.367174vw;
        }
      }

      .modal-search {
        position: absolute;
        top: 6.1vw;
        left: 9.729272vw;
        border: .5vw solid #808080;
        border-radius: 50%;
        width: 12vw;
        height: 12vw;
        display: flex;
        justify-content: center;
        align-items: center;
        .icon.search {
          background-color: var(--lz-primary);
          width: 6.367174vw;
          height: 6.367174vw;
        }
      }
    }

    .corpus {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding-top: 9.64467vw;
      padding-left: 8.087986vw;
      padding-bottom: 10.879865vw;
      /* border-bottom: 1.884941vw solid var(--lz-secondary); */

      .navigation-menu {
        display: flex;
        flex-direction: column;
        margin-bottom: 8.510998vw;

        .navigation-link {
          font-family: "Nunito-Black";
          font-size: 9.306261vw;
          line-height: 17.460237vw;
          color: var(--lz-primary);
          text-decoration: none;

          .icon {
            background-color: var(--lz-primary);
          }
        }

        .submenu {
          display: none;
        }

        .submenu.active {
          display: flex;
          flex-direction: column;
          padding-left: 8.087986vw;
          text-transform: capitalize;
          line-height: 10vw;
          font-size: 8vw;
        }
      }

      .rrss {
        display: flex;
        margin-bottom: 7.106599vw;

        .circle {
          background-color: var(--lz-secondary);
          width: 13.989848vw;
          height: 13.989848vw;
          margin-right: 6.345178vw;
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;

          .icon {
            background-color: var(--lz-quaternary);
            width: 22px;
            height: 22px;
          }

          .icon.fb {
            width: 3.681895vw;
            height: 7.810491vw;
          }

          .icon.insta {
            width: 7.730964vw;
            height: 7.730964vw;
          }

          .icon.whats {
            width: 8.101523vw;
            height: 8.101523vw;
          }
        }
      }

      .contact {
        display: flex;
        flex-direction: column;

        a {
          font-family: "Nunito-Black";
          text-decoration: none;
          font-size: 4.582064vw;
          line-height: 6.810491vw;
          color: var(--lz-primary);
        }
      }
    }
  }

  #home {
    overflow: hidden;

    #header {
      position: relative;
    }

    #features {
      .numbers {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 107.3px 20.812183% 67.2px 20.812183%;

        .divisor {
          display: none;
        }

        .item {
          margin-bottom: 49px;
        }

        .count {
          font-size: 15vw;
          line-height: 15vw;
        }

        .text {
          font-size: 7vw;
          line-height: 7vw;
        }
      }

      .content {
        position: relative;

        .commitment {
          display: flex;
          flex-direction: column;
          align-items: center;
          padding: 130.2px 5.769882% 263.1px 5.769882%;
          margin-top: 0;
          padding-bottom: 0;

          .text {
            max-width: 100%;
            width: 100%;

            .title p {
              font-size: 12.013536vw;
              line-height: 11vw;
            }

            .body p {
              @extend .global-body;
            }
          }

          img {
            max-width: 100%;
            margin-top: 21px;
          }
        }

        .ingredients {
          display: flex;
          flex-direction: column;
          align-items: center;
          margin-top: 25vw;
          padding-bottom: 0;

          .item {
            display: flex;
            flex-direction: column;
            align-items: center;
            margin-bottom: 70.8px;

            .circle {
              width: 36.379019vw;
              height: 36.379019vw;

              .milk {
                width: 10.998308vw;
                height: 18.746193vw;
              }

              .not-aditive {
                height: 22.387479vw;
                width: 22.387479vw;
              }

              .fruit {
                height: 16.920474vw;
                width: 18.043993vw;
              }
            }

            .text {
              p {
                font-size: 8.291032vw;
                line-height: 8.291032vw;
              }
            }
          }
        }

        .background {
          position: absolute;
          top: 0;
          left: 0;
          height: 1106.49px;
          width: 100%;
        }
      }
    }

    #banner {
      .carousel-indicators {
        display: flex;
        justify-content: space-around;
        bottom: -10vw;

        li {
          background-color: var(--lz-quaternary);
          border: 2px solid var(--lz-secondary);
          width: 4.230118vw;
          height: 4.230118vw;
        }

        li.active {
          background-color: var(--lz-secondary);
        }
      }
    }

    #flavor {
      display: flex;
      flex-direction: column-reverse;
      padding: 100.9px 5.837563% 110.4px 5.837563%;

      .text {
        max-width: 100%;
        width: 100%;
        margin-bottom: 38.4px;

        .title {
          p {
            font-size: 12.013536vw;
            line-height: 12.013536vw;
          }
        }

        .body {
          margin-top: 27.9px;

          p {
            font-size: 4.737733vw;
            line-height: 8.460237vw;
          }
        }
      }

      .content-image {
        max-width: 100%;
      }

      .background {
        width: 170vw;
      }
    }

    .modal-pedidos-container {
      .modal-pedidos {
        width: 87.14044vw;
        display: flex;
        flex-direction: column;
        padding: 9.086294vw 4.873096vw 6.05753vw 4.873096vw;

        .modal-image-container {
          width: 77.411168vw;
          margin-bottom: 1.569444vw;
          
          img {
            border-radius: 6.395939vw;
            width: 100%;
          }
        }

        .modal-content-container {
          width: 75.803723vw;
          .modal-close-circle {
            cursor: pointer;
            width: 6.192893vw;
            height: 6.192893vw;
            top: 1.979695vw;
            left: 78.91709vw;
            .close.icon {
              width: 3.265651vw;
              height: 3.265651vw;
            }
          }

          .pedidos-ya-icon.icon {
            width: 41.522843vw;
            height: 9.357022vw;
          }

          .modal-text-container {
            margin-bottom: 3.299492vw;

            .modal-text {
              font-size: 4.23181vw;
              line-height: 5.079526vw;
            }
          }

          .modal-link {
            border-radius: 6.725888vw;
            font-size: 6.395939vw;
            padding: 0.71066vw 1.996616vw 0.744501vw 1.996616vw;
          }
        }
      }
    }
  }

  #catalogue,
  #category {
    #products {
      padding: 0;

      .title {
        /* @extend .global-subtitle; */
        font-size: 9.306261vw;
        line-height: 9.306261vw;
      }

      .gallery {
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        align-items: center;
        justify-content: flex-start;

        .item {
          padding: 0;
          width: 83.294416vw !important;

          .title {
            font-size: 5.752961vw;
            line-height: 5.752961vw;
            margin-top: 3vw;
          }

          img {
            border-radius: 7vw;
          }
        }
      }

      .subcategories {
        .name {
          font-size: 5.752961vw;
          height: 7vw;
          line-height: 5.752961vw;
          border-radius: 3vw;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-bottom: 3vw;
          margin-left: 8vw;
        }
      }

      .open-gallery {
        margin-bottom: 7vw;

        .view {
          font-size: 5.752961vw;
          line-height: 5.752961vw;
          height: 7vw;
          border-radius: 3vw;
        }
      }
    }

    #categories {
      display: flex;
      flex-direction: column;
      justify-content: center;

      .wave-top {
        margin-bottom: -1vw;
      }

      .content {
        padding: 0;
        align-items: center;

        .title {
          /* @extend .global-subtitle; */
          font-size: 9.306261vw;
          line-height: 9.306261vw;
          padding: 0;
          margin-bottom: 7vw;
        }

        .gallery {
          display: flex;
          flex-direction: column;
          flex-wrap: nowrap;
          align-items: center;
          justify-content: flex-start;

          .item {
            padding: 0;
            width: 83.294416vw !important;
            max-width: 83.294416vw !important;
            margin-bottom: 7vw;

            .title {
              font-size: 5.752961vw;
              line-height: 5.752961vw;
              margin-top: 3vw;
              height: 7vw;
              border-radius: 3vw;
            }

            img {
              border-radius: 7vw;
            }
          }
        }
      }
    }
  }

  #product-details {
    .modal-body-content {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    .left,
    .right {
      width: 86.404399vw;
    }

    .left {
      margin-bottom: 7vw;

      .banner-product {
        max-width: 100%;
        width: 100%;
        border-radius: 7vw;
      }
    }

    .parent,
    .line,
    .formula {
      font-size: 4.981387vw;
      line-height: 4.981387vw;
      border-radius: 3vw;
      margin-bottom: 3vw;
      height: 6.307953vw;
      padding: 1vw 2.707276vw;
    }

    .base {
      height: 6.307953vw;
      padding: 1vw 2.707276vw 1vw 0;
      border-radius: 3vw;
      margin-bottom: 3vw;

      .text-base {
        font-size: 4.981387vw;
        line-height: 4.981387vw;
        margin-left: 2.707276vw;
      }

      .icon {
        width: 9vw;
        height: 9vw;

        .milk,
        .fruit {
          width: 6vw;
          height: 6vw;
        }
      }
    }

    .include-box {
      @extend .ExtraBold;
      justify-content: center;
      align-items: center;
      font-size: 4.981387vw;
      line-height: 4.981387vw;

      .left-box {
        display: flex;
        flex-direction: column;
        color: var(--lz-primary);

        .kind {
          padding: 0.5vw 2vw;
          border-radius: 2vw;
          margin-bottom: 2vw;
          width: fit-content;
          text-align: center;
        }

        .qty {
          padding-left: 2px;

          span {
            color: var(--lz-secondary);
          }
        }
      }

      .line-box {
        height: 15vw;
        margin: 0 3vw;
      }

      .right-box {
        border-radius: 2vw;
        padding: 2vw;
      }
    }

    .title {
      margin-bottom: 3vw;
    }

    .subtitle {
      font-size: 4.737733vw;
      line-height: 5vw;
      margin-bottom: 3vw;
    }

    .ingredients {
      .item {
        font-size: 3.565144vw;
        line-height: 3.565144vw;
        margin-right: 3vw;
        margin-bottom: 3vw;
        padding: 1vw 2vw;
      }
    }

    .modal-close {
      right: 6.852792vw;

      .text {
        font-size: 3.565144vw;
      }
    }
  }

  #us {
    #banner {
      .background {
        height: 171.299492vw;
        width: auto;
        margin-left: -137vw;
      }
    }

    #mission-vision {
      margin-top: -7.517766vw;
      padding-bottom: 55px;
    }

    .content {
      .header {
        font-size: 15.059222vw;
        line-height: 15.059222vw;
      }

      .body {
        font-size: 4.737733vw;
        line-height: 6vw;
        text-align: left;
      }
    }

    .sections {
      .content {
        padding: 0 6.80203vw;
        padding-top: 13.840948vw;
      }
    }

    #history {
      .content {
        padding-top: 36.6px;

        .header {
          color: var(--lz-secondary);
        }

        .body {
          p {
            margin-top: 37.7px;
          }
        }
      }
    }

    #factory {
      .background {
        width: auto;
        height: 154.267343vw;
        margin-left: -300px;
      }

      .wave-vol {
        width: 44.477157vw;
      }
    }

    #banner {
      .text {
        font-size: 15.059222vw;
        line-height: 15.059222vw;
        top: 69.492386vw;
      }
    }

    #whoweare {
      .content {
        .header {
          text-align: center;
          font-size: 10.033841vw;
          line-height: 10.033841vw;
          margin-bottom: 27.7px;
        }

        img {
          margin-bottom: 21.895093vw;
        }
      }
    }
  }

  #loader {
    .status {
      .text {
        font-size: 5.076142vw;
      }
    }
  }

  #contact {
    overflow: hidden;

    .information {
      padding: 15.736041vw 6.80203vw 5.059222vw 6.80203vw;

      .title {
        font-size: 15.059222vw;
        line-height: 15.059222vw;
      }

      .steps-container {
        display: flex;
        flex-direction: column;
        align-items: center;

        .step {
          width: 100%;
          display: flex;
          align-items: center;
          margin-bottom: 0;

          .title {
            font-size: 9.306261vw;
            border-radius: 9.306261vw;
            padding: 0.1vw 4.331641vw;
            line-height: 12.690355vw;
            margin-bottom: 2.284264vw;
          }

          .body {
            font-size: 4.73vw;
            line-height: 8.527919vw;
          }
        }

        .divisor {
          background-color: var(--lz-quaternary);
          width: 1vw;
          height: 103.34px;
          margin-bottom: 26px;
          margin-top: 26px;
        }
      }
    }

    .register {
      padding: 30.642978vw 10.101523vw 38.443316vw 10.101523vw;
      overflow: visible;

      .head {
        .title {
          font-size: 13.028765vw;
          line-height: 13.028765vw;
          white-space: inherit;
        }
      }

      .register-information {
        .contact-form {
          input::placeholder {
            color: var(--lz-quaternary);
            // font-size: 5.752961vw;
            font-size: 2.612961vw;
          }

          input:focus {
            color: var(--lz-quaternary);
            // font-size: 5.752961vw;
            font-size: 2.612961vw;
            border: none;
            outline: none;
            border-bottom: 1px solid var(--lz-quaternary);
          }

          input {
            // font-size: 5.752961vw;
            font-size: 2.612961vw;
          }
        }

        .send {
          font-size: 5.752961vw;
          padding: 2.707276vw 11.844332vw;
          border-radius: 9vw;
        }
      }
    }

    .left-mota {
      top: unset;
      bottom: -31.978003vw;
      left: -5px;
      width: 28vw;
    }

    .right-mota {
      bottom: unset;
      top: 68px;
      width: 51.683587vw;
      right: -36vw;
      transform: rotate(-45deg);
    }

    .modal-form {
      .modal-container {
        // background-color: blue;
        width: 80.915398vw;
        border-radius: 11.389171vw;
  
        .modal-check {
          width: 17.433164vw;
          height: 17.433164vw;
          margin-top: 7.241963vw;
          margin-bottom: 9.187817vw;

          .icon.white-check {
            background-color: var(--lz-primary);
            height: 6.874788vw;
            width: 9.397631vw;
            margin: 5.989848vw 4.010152vw 4.568528vw 4.010152vw;
          }
        }

        .modal-text-container {
          margin-bottom: 5.516074vw;
          .modal-text {
            font-size: 11.895093vw;
            line-height: 11.245347vw;
          }
        }

        .modal-close {
          font-size: 6.483926vw;
          display: flex;
          justify-content: center;
          border-radius: 6.725888vw;
          margin-bottom: 15.36379vw;
          padding: 3.113367vw 4.365482vw 3.079526vw 4.686971vw;
        }
  
      }
    }
  }
}